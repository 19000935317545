import { Tooltip, Typography } from "@mui/material"
import { useContext, useMemo } from "react"
import { MasterDataContext } from "../App"
import { IndicatorDescriptors } from "../views/attributes/Attributes"
import IndicatorSelect from "./IndicatorSelect"

const getParentIndicator = (indr) => {
	const indicator = indr.toString()

	if (indicator === "Country Score" || indicator.length === 1 || indicator.length === 3) {
		return indicator
	} else {
		return indicator.slice(0, 3)
	}
}

const segregateIndicatorOptions = (indicators) => {
	return indicators.reduce((accum, { label, value }) => {
		if (value === "Overall") return accum

		if (value.length === 1) {
			accum[value] = { label, value, groups: {} }
		} else if (value.length === 3) {
			const [main, sub] = value.split(".")
			accum[main].groups[sub] = {
				label,
				value,
				options: [{ value, label: "Overall" }],
			}
		} else if (value.length >= 5) {
			const [main, sub] = value.split(".")
			accum[main].groups[sub].options.push({ label, value })
		}
		return accum
	}, {})
}

export default function IndicatorSelectors({ onChange, value }) {
	const { indicators } = useContext(MasterDataContext)

	const indicatorOptions = useMemo(
		() => Object.values(segregateIndicatorOptions(indicators)),
		[indicators]
	)

	const indicatorsByValue = useMemo(
		() =>
			indicators.reduce((accum, indicator) => {
				accum[indicator.value] = indicator.label
				return accum
			}, {}),
		[indicators]
	)

	const getIndicatorLabelForDisplay = (value) => {
		if (value === "Country Score") return value

		const indicator = indicatorsByValue[value]

		if (value.length === 5) {
			const parentGroupKey = value.slice(0, 3)
			const prefix = indicatorsByValue[parentGroupKey]
			const suffix = ` (${indicator})`
			return prefix + suffix
		} else {
			return indicator
		}
	}

	return (
		<div className="indicator-section-container">
			{indicatorOptions.map((section) => (
				<div key={section.value}>
					<Typography
						className="heading"
						onClick={() => onChange(section.value)}
						color={value === section.value ? "steelblue" : "black"}
					>
						{section.label.toUpperCase()}
					</Typography>
					<div className="indicator-group-container">
						{Object.values(section.groups).map((select) => (
							<div key={select.label}>
								<IndicatorSelect
									groupId={select.value}
									label={select.label}
									options={select.options}
									value={value}
									onChange={onChange}
								/>
							</div>
						))}
					</div>
				</div>
			))}
			<Tooltip title={IndicatorDescriptors[getParentIndicator(value)]} arrow>
				<h3 className="indicator-label strong">{getIndicatorLabelForDisplay(value)}</h3>
			</Tooltip>
			<div
				className={"strong reset-indicators " + (value !== "Country Score" ? "visible" : "")}
				onClick={() => onChange("Country Score")}
			>
				Reset to Overall Score
			</div>
		</div>
	)
}

const leftContent = [
	{
		title: "What",
		body: [
			"In-depth assessment of social protection responses in South and Southeast Asia",
			"Digital platform for easy access and comparison across indicators; enable ease of response coordination",
		],
	},
	{
		title: "Framework for Assessment",
		body: ["Policies and provisions of social protection", "Migrants as unit of analysis"],
	},
	{
		title: "How",
		body: [
			"Examines a combination of government social protection policies, community/civil society response mechanisms, and international and transnational actions",
		],
	},
	{
		title: "Details",
		body: [
			"Timeframe: pre- and during COVID-19",
			"Pilot study: 4 countries, 2 domains, 7 indicators, 36 sub-indicators",
		],
	},
]

const rightContent = [
	{
		title: "Why the Social Resilience Index?",
		body: [
			`South and Southeast Asia: high risk, disaster-prone areas`,
			`Social protection as key component of preparedness, resilience, and adaptability to crisis`,
			`Limited assessment on social protection mechanisms (e.g. see ASEAN Rapid Assessment)`,
			`Migrants typically approached as a separate entity, unrelated to the rest of society`,
			`Yet, high dependency on migrants in the region`,
		],
	},
]

export const team = [
	{
		name: "Akanksha Narode",
		avatar: "/images/akanksha.png",
		title: "Methodology",
		subTitle: "Digital Platform",
	},
	{
		name: "Akshita Nanda",
		avatar: "/images/akshita.png",
		title: "Migrant Welfare",
		subTitle: "Singapore, Malaysia",
	},
	{
		name: "Athena Presto",
		avatar: "/images/athena.png",
		title: "Philippines",
		subTitle: "Social Protection",
	},
	{ name: "Isarudin", avatar: "/images/isarudin.png", title: "Indonesia, Malaysia", subTitle: "" },
	{
		name: "Keith Detros",
		avatar: "/images/keith.png",
		title: "Methodology",
		subTitle: "Philippines",
	},
	{ name: "Jessica Li", avatar: "/images/jessica.png", title: "Malaysia, Indices", subTitle: "" },
	{
		name: "Kaize Zhu",
		avatar: "/images/kaize.png",
		title: "Malaysia, Infographics",
		subTitle: "",
	},
	{
		name: "Natasha Gray",
		avatar: "/images/natasha.png",
		title: "Project Management",
		subTitle: "Singapore, Digital Outputs",
	},
	{
		name: "Ravija Harija",
		avatar: "/images/ravija.png",
		title: "Malaysia, Methodology",
		subTitle: "",
	},
]
export function AboutUs() {
	return (
		<div className="chart-section">
			<div className="chart">
				<h3 className="strong x-center">About Us</h3>
				<div style={{ margin: "0 auto", width: "600px" }}>
					The Social Resilience Index tracks social protection as part of Covid-19 response mechanisms
					across the Asia-Pacific as a way of assessing the region's collective ability to withstand,
					recover, and adapt to external and internal shocks. <br />
					<br />
					The current pilot is based on an initial study of four countries. The next phase of the project
					envisions expansion of the work to additional six countries in the Asia-Pacific.
				</div>
				<div className="about-us">
					<div>
						{leftContent.map((section) => (
							<div key={section.title}>
								<h4 className="strong">{section.title}</h4>
								<ul>
									{section.body.map((item) => (
										<li>{item}</li>
									))}
								</ul>
							</div>
						))}
					</div>
					<div>
						{rightContent.map((section) => (
							<div key={section.title}>
								<h4 className="strong">{section.title}</h4>
								<ul>
									{section.body.map((item) => (
										<li>{item}</li>
									))}
								</ul>
							</div>
						))}
					</div>
				</div>
				<div className="spacer"></div>
				<h3 className="strong x-center">Our Team</h3>
				<div>
					<div className="team-grid">
						{team.map((member) => (
							<div key={member.name} className="member">
								<div className="img-wrapper">
									<img src={member.avatar} />
								</div>
								<div>
									<h5 className="strong">{member.name}</h5>
									<div>{member.title}</div>
									<div>{member.subTitle}</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

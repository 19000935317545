import { countries, countriesMappedByCode } from "../data"

export function CountryComparisonSelector({ selectedCountries, setSelectedCountries }) {
	const addCountry = (country) => {
		setSelectedCountries((p) => (!p.includes(country) ? [...p, country] : p))
	}

	const removeCountry = (country) => {
		setSelectedCountries((p) => p.filter((selectedCountry) => country !== selectedCountry))
	}
	return (
		<nav className="country-compare-select">
			<div className="strong" style={{ marginBottom: "10px", textAlign: "center" }}>
				Compare Countries
			</div>
			{selectedCountries.length > 0 ? (
				selectedCountries.map((country, index) => (
					<div
						key={country}
						style={{
							display: "flex",
							justifyContent: "space-between",
							padding: "10px",
						}}
					>
						<div style={{ fontWeight: "bold" }}>{countriesMappedByCode[country].label}</div>
						<div
							style={{ color: "black" }}
							onClick={index === 0 ? () => {} : () => removeCountry(country)}
							className={`strong button ${index === 0 && "disabled"}`}
						>
							-
						</div>
					</div>
				))
			) : (
				<div className="strong" style={{ textAlign: "center" }}>
					None
				</div>
			)}
			<hr />
			{countries.map(
				(country) =>
					!selectedCountries.includes(country.value) && (
						<div
							key={country.value}
							style={{
								display: "flex",
								justifyContent: "space-between",
								padding: "10px",
							}}
						>
							<div>{country.label}</div>
							<div
								style={{ cursor: "pointer", color: "black" }}
								onClick={() => addCountry(country.value)}
								className="strong button"
							>
								+
							</div>
						</div>
					)
			)}
		</nav>
	)
}

export const DynamicCaretIcon = ({ up, color }) => {
	return (
		<svg
			width={12}
			height={12}
			style={{
				transform: `rotateZ(${up ? "180deg" : "0deg"})`,
				transition: "transform 0.2s ease",
			}}
		>
			<path
				d="M 1 3, L 6 8, L 11 3"
				fill="none"
				stroke={color || "black"}
				strokeWidth={2}
				strokeLinecap="round"
			/>
		</svg>
	)
}

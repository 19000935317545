import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function DropdownSelect({ label, onChange, value, options }) {
	const optionValues = React.useMemo(
		() => options.map((opt) => opt.value),
		[options]
	);

	const selectValue = optionValues.includes(value) ? value : "";
	return (
		<FormControl fullWidth size="small">
			<InputLabel>{label}</InputLabel>
			<Select
				value={selectValue}
				label={label}
				onChange={(e) => onChange(e.target.value)}
			>
				{options.map((opt) => (
					<MenuItem key={opt.value} value={opt.value}>
						{opt.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

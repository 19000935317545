import { scaleLinear } from "d3"
import { useEffect, useState, useCallback, useContext, useMemo, useRef } from "react"
import { MasterDataContext } from "../../App"
import WorldMap from "../../components/WorldMap"

import { countries } from "../../data"

export const useDebounceCallback = (fn, delay, deps) => {
	const timeoutRef = useRef(null)

	return useCallback(
		(...args) => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current)
			}
			timeoutRef.current = setTimeout(() => {
				fn(...args)
			}, delay)
		},
		[...deps, fn, delay]
	)
}

const getColor = scaleLinear().domain([1, 7]).range(["#F4476F", "#5398EC"])

const generateHeptagon = (radius) => {
	const radianAngles = []
	for (let i = 0; i < 7; i++) {
		const angle = ((Math.PI * 2) / 7) * i
		radianAngles.push({ sin: Math.sin(angle), cos: Math.cos(angle) })
	}
	const sides = radianAngles.map(({ sin, cos }, ind) => {
		const nextInd = ind === 6 ? 0 : ind + 1
		const { sin: nSin, cos: nCos } = radianAngles[nextInd]
		return [
			[cos * radius, sin * radius],
			[nCos * radius, nSin * radius],
		]
	})
	const paths = sides.map((points, ind) => {
		let path = "M"
		points.forEach((point, ind) => {
			if (ind === 1) {
				path += ` ${point[0]} ${point[1]} L 0 0`
			} else {
				path += ` ${point[0]} ${point[1]} L`
			}
		})
		return { path, fill: getColor(ind + 1), stroke: "black", strokeWidth: "1px" }
	})
	return paths
}

const generateSolidHeptagon = (radius) => {
	const radianAngles = []
	for (let i = 0; i < 7; i++) {
		const angle = ((Math.PI * 2) / 7) * i
		radianAngles.push({ sin: Math.sin(angle), cos: Math.cos(angle) })
	}
	const points = radianAngles.map(({ sin, cos }) => {
		return [cos * radius, sin * radius]
	})
	let path = "M"
	points.forEach((point, ind) => {
		path += ` ${point[0]} ${point[1]} ${ind < 6 ? "L" : "Z"}`
	})
	return path
}

const innerHeptagon = generateSolidHeptagon(90)
const outerHeptagon = generateHeptagon(110)

export default function MapChart({ mapData, indicator, onCountrySelect, onCountryHover }) {
	const { indicators } = useContext(MasterDataContext)

	const indicatorIds = useMemo(
		() =>
			indicators
				.reduce((accum, indr) => {
					if (indr.value.length === 3) {
						accum.push(indr.value)
					}
					return accum
				}, [])
				.sort(),
		[indicators]
	)

	const [activeCountry, setActiveCountry] = useState("")
	const { data, min, max } = mapData

	const getScaleFactor = useCallback(
		(value) => {
			return 1.5
			// if (!value || !(value - min)) {
			// 	return 1
			// }
			// return 1 + ((value - min) / (max - min)) * 0.5
		},
		[min, max]
	)

	useEffect(() => {
		onCountryHover(activeCountry)
	}, [activeCountry, onCountryHover])

	const queueSetActiveCountry = useDebounceCallback(
		(country) => {
			setActiveCountry(country)
		},
		100,
		[]
	)

	return (
		<div className="map-container">
			<div className="map-view">
				<WorldMap activeCountry={activeCountry} />
				{countries.map((country) => (
					<div
						key={country.value}
						className="country"
						onClick={() => {
							onCountrySelect(country.value)
						}}
						id={country.value}
						onMouseOver={() => queueSetActiveCountry(country.value)}
						onMouseOut={() => queueSetActiveCountry("")}
					>
						<svg
							viewBox="-200 -200 400 400"
							width="140px"
							height="140px"
							xmlns="http://www.w3.org/2000/svg"
							className="country-svg"
							fontFamily="Roboto"
						>
							<g
								style={{
									transform: `scale(${getScaleFactor(data[country.value] || 0)})`,
									transition: "transform 0.5s ease-in-out",
								}}
								strokeWidth={1}
								stroke="#747474"
							>
								{outerHeptagon.map((path, ind) => (
									<path
										id={indicatorIds[ind]}
										fill={
											indicator === "Country Score" ||
											(indicator.length === 1 && indicatorIds[ind].startsWith(indicator)) ||
											indicator.startsWith(indicatorIds[ind])
												? path.fill
												: "#d6d6d6"
										}
										key={ind}
										d={path.path}
									/>
								))}
							</g>
							<g>
								<path fill="white" stroke="#747474" d={innerHeptagon} />
							</g>
							<text
								x={0}
								y={0}
								transform="rotate(90)"
								textAnchor="middle"
								style={{ fontWeight: "bold", fontSize: "90px" }}
							>
								{data[country.value]}
							</text>
							<text
								x={0}
								y={0}
								dy={"1.3em"}
								transform="rotate(90)"
								textAnchor="middle"
								style={{ fontWeight: "bold", fontSize: "54px" }}
							>
								{country.value}
							</text>
						</svg>
						{/* <div
							style={{
								position: "absolute",
								top: "0px",
								left: "0px",
								width: "100%",
								height: "100%",
								display: "none",
							}}
						>
							<div
								style={{
									height: "100%",
									textAlign: "center",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
								}}
							>
								<div className="measure">{data[country.value]?.toString() || "N/A"}</div>
								<div className="strong">{country.value}</div>
							</div>
						</div> */}
					</div>
				))}
			</div>
		</div>
	)
}

export const attributes = [
  { label: "Overall", value: "Average" },
  { label: "Migrant worker", value: "Migrant worker" },
  { label: "Dependent", value: "Dependent" },
  { label: "Refugee", value: "Refugee" },
  { label: "Return migrant", value: "Return migrant" },
  { label: "Undocumented", value: "Undocumented" }
];

export const countries = [
  { label: "Indonesia", color: "#78f58d", value: "ID" },
  { label: "Malaysia", color: "#89aff5", value: "MY" },
  { label: "Philippines", color: "#f5cd71", value: "PH" },
  { label: "Singapore", color: "#ff9289", value: "SG" }
];

export const periods = {
  BEFORE_COVID: "Before COVID-19 (up to and inclusive of 2019)",
  DURING_COVID_ANNUAL: "During COVID-19 – Annual with consistency indicator",
  DURING_COVID_Q1: "During Covid-19 - Q1 (March - June 2020)",
  DURING_COVID_Q2: "During Covid-19 - Q2 (July - September 2020)",
  DURING_COVID_Q3: "During Covid-19 - Q3 (October - December 2020)",
  DURING_COVID_Q4: "During Covid-19 - Q4 (January - March 2021)",
  SYSTEMIC_RESILIENCE: "Systemic Resilience (Q4 - Q1)"
};

const countriesMappedByName = countries.reduce((accum, value) => {
  accum[value.label] = value;
  return accum;
}, {});

export const countriesMappedByCode = countries.reduce((accum, c) => {
  accum[c.value] = c;
  return accum;
}, {});

export const periodLabels = [
  {
    value: periods.BEFORE_COVID,
    label: "Before COVID-19 (Upto '19)"
  },
  {
    value: periods.DURING_COVID_ANNUAL,
    label: "During COVID-19 - Annual"
  },
  {
    value: periods.DURING_COVID_Q1,
    label: "Mar - Jun '20"
  },
  {
    value: periods.DURING_COVID_Q2,
    label: "Jul - Sep '20"
  },
  {
    value: periods.DURING_COVID_Q3,
    label: "Oct - Dec '20"
  },
  {
    value: periods.DURING_COVID_Q4,
    label: "Jan - Mar '21"
  }
];

export const periodLabelsByValue = periodLabels.reduce((accum, value) => {
  accum[value.value] = value.label;
  return accum;
}, {});

export const annualPeriods = [
  {
    label: periods.BEFORE_COVID,
    value: 0
  },
  {
    label: periods.DURING_COVID_ANNUAL,
    value: 100
  }
];

export const quarterlyPeriods = [
  {
    label: periods.DURING_COVID_ANNUAL,
    value: 0
  },

  {
    label: periods.DURING_COVID_Q1,
    value: 25
  },
  {
    label: periods.DURING_COVID_Q2,
    value: 50
  },
  {
    label: periods.DURING_COVID_Q3,
    value: 75
  },
  {
    label: periods.DURING_COVID_Q4,
    value: 100
  }
];

export const resiliencePeriods = [
  { label: periods.DURING_COVID_Q1, value: 0 },
  { label: periods.DURING_COVID_Q4, value: 100 }
];

export const getFlatData = (data) => {
  let mainData = [],
    resilienceData = [],
    indicators = new Set();
  data.forEach((point) => {
    point.countryCode = countriesMappedByName[point.country].value;
    if (point.period === periods.SYSTEMIC_RESILIENCE) {
      resilienceData.push(point);
    } else {
      mainData.push(point);
    }
    if (point["Indicators"]) {
      indicators.add(point["Sr.No."] + "\t" + point["Indicators"].trim());
    }
  });
  return {
    resilienceData: resilienceData,
    flatData: mainData,
    indicators: Array.from(indicators).map((indicator) => ({
      label: indicator.split("\t")[1],
      value: indicator.split("\t")[0]
    }))
  };
};

// export const { resilienceData, flatData, indicators } = getFlatData(data)

export const getMapData = (
  flatData,
  resilienceData,
  period,
  indicator,
  attribute = "Average"
) => {
  let min = null,
    max = null,
    dataSource = flatData;

  if (period === periods.SYSTEMIC_RESILIENCE) dataSource = resilienceData;
  const data = dataSource.reduce((accum, point) => {
    const isPeriodMatching =
      period === periods.SYSTEMIC_RESILIENCE || point.period === period;
    if (isPeriodMatching && point["Sr.No."] == indicator) {
      const value = parseInt(point[attribute]);
      accum[point.countryCode] = value;
      if (min === null) {
        min = value;
        max = value;
      } else if (value < min) {
        min = value;
      } else if (value > max) {
        max = value;
      }
    }
    return accum;
  }, {});
  return { data, min, max };
};

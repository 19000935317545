import { Tooltip } from "@mui/material"
import { attributes } from "../../data"
export const attributeDescriptions = {
	Average:
		"All persons who have moved away from his or her usual place of residence across an international border in any of the five categories below",
	"Migrant worker":
		"A person who is engaged or employed in a remunerated activity at any point during the COVID-19 pandemic in a state of which he or she is not a national",
	Dependent:
		"A person whose status in the receiving country is contingent on the status of the principal migrant, usually a family member",
	Refugee:
		"A person who has fled their country for reasons of war, violence, conflict, or persecution, and are seeking asylum in another country",
	"Return migrant":
		"A person who has returned to their country of citizenship during the COVID-19 pandemic, either voluntarily or forced",
	Undocumented:
		"A person who is in an irregular situation and lacking the proper authorization to legally stay in a receiving state",
}
export function AttributeMenu({ attribute, setAttribute }) {
	return (
		<div className="attribute-list">
			<h3 className="strong">Attributes</h3>
			{attributes.map((attr) => (
				<Tooltip placement="left" arrow title={attributeDescriptions[attr.value]} key={attr.value}>
					<button
						key={attr.value}
						className={
							(attribute === attr.value ? "active" : "") +
							" " +
							(attr.label === "Overall" ? "overall" : "")
						}
						onClick={() => setAttribute(attr.value)}
					>
						{attr.label}
					</button>
				</Tooltip>
			))}
		</div>
	)
}

import { Button, Link } from "@mui/material"
import { useContext, useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { MasterDataContext } from "../../App"
import { CountryComparisonBar } from "../../components/CountryComparisonBar"
import { CountryComparisonSelector } from "../../components/CountryComparisonSelector"
import { SummaryChart } from "../../components/SummaryChart"
import { Timeline } from "../../components/Timeline"
import { attributes } from "../../data"
import RadialChart from "../../RadialChart"

export const attributeKeys = attributes.reduce((accum, attr) => {
	if (attr.value !== "Average") {
		accum.push(attr.value)
	}
	return accum
}, [])

const getInitialAccum = () => {
	return attributeKeys.reduce((accum, attr) => {
		accum[attr] = { attribute: attr }
		return accum
	}, {})
}
export function CountryView({
	period,
	indicator,
	onStateChange,
	selectedCountries,
	setSelectedCountries,
}) {
	const { flatData } = useContext(MasterDataContext)
	const navigate = useNavigate()
	const { countryId } = useParams()
	const { comparisonData, radialData } = useMemo(() => {
		let comparisonData = {}
		const radialData = Object.values(
			flatData.reduce((accum, value) => {
				if (
					period === value.period &&
					indicator == value["Sr.No."] &&
					selectedCountries.includes(value.countryCode)
				) {
					comparisonData[value.countryCode] = value
					attributeKeys.forEach((attr) => {
						accum[attr][value.country] = parseInt(value[attr] || 0) + 10
					})
				}
				return accum
			}, getInitialAccum())
		)
		return { comparisonData, radialData }
	}, [period, indicator, selectedCountries])

	useEffect(() => {
		return () => setSelectedCountries([])
	}, [])

	useEffect(() => {
		setSelectedCountries([countryId])
	}, [countryId, setSelectedCountries])

	return (
		<div className="chart-section">
			<div className="chart" style={{ position: "relative" }}>
				<Link sx={{ position: "sticky" }} href={`/country-summary?country=${countryId}`}>
					<Button variant="contained">View Summary</Button>
				</Link>
				<RadialChart chartData={radialData} selectedCountries={selectedCountries} />
				<Timeline setPeriod={onStateChange("period")} period={period} />
				<CountryComparisonBar
					chartData={comparisonData}
					period={period}
					indicator={indicator}
					selectedCountries={selectedCountries}
				/>
			</div>
			<CountryComparisonSelector
				selectedCountries={selectedCountries}
				setSelectedCountries={setSelectedCountries}
			/>
		</div>
	)
}

import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useContext, useEffect, useMemo, useState } from "react"
import BumpChart from "./BumpChart"
import ButtonList from "../../components/ButtonList"
import { getMapData, periods } from "../../data"
import MapChart from "./MapChart"
import { AttributeMenu } from "./AttributeMenu"
import { MasterDataContext } from "../../App"

export function Dashboard({ period, indicator, attribute, onStateChange, setCountry }) {
	const { flatData, resilienceData } = useContext(MasterDataContext)
	const [mapView, setMapView] = useState("before")
	const [highlightCountry, setHighlightCountry] = useState("")

	const mapData = useMemo(() => {
		return getMapData(flatData, resilienceData, period, indicator, attribute)
	}, [period, indicator, attribute, flatData, resilienceData])

	useEffect(() => {
		let period =
			mapView === "before"
				? periods.BEFORE_COVID
				: mapView === "during"
				? periods.DURING_COVID_ANNUAL
				: periods.SYSTEMIC_RESILIENCE
		onStateChange("period")(period)
	}, [mapView, onStateChange])

	return (
		<div className="chart-section">
			<div className="chart">
				<ToggleButtonGroup
					value={mapView}
					exclusive
					onChange={(e) => setMapView(e.target.value)}
					style={{ margin: "auto" }}
				>
					<ToggleButton value="before">Before COVID-19</ToggleButton>
					<ToggleButton value="during">During COVID-19</ToggleButton>
					<ToggleButton value="sys-res">Change</ToggleButton>
				</ToggleButtonGroup>
				<MapChart
					mapData={mapData}
					indicator={indicator}
					onCountrySelect={setCountry}
					attribute={attribute}
					onCountryHover={setHighlightCountry}
				/>

				<BumpChart
					period={period}
					indicator={indicator}
					attribute={attribute}
					highlightCountry={highlightCountry}
				/>
			</div>

			<AttributeMenu setAttribute={onStateChange("attribute")} attribute={attribute} />
		</div>
	)
}

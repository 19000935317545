import { useState } from "react"
import { countries } from "../../data"
import countryProfiles from "../country-view/country-profiles.json"

export function CountryProfiles() {
	const [country, setCountry] = useState(countries[0])

	return (
		<div className="chart-section">
			<div className="chart">
				<div className="country-menu">
					{countries.map((c) => (
						<button
							key={c.value}
							onClick={() => setCountry(c)}
							className={"strong " + (c.value === country.value ? "active" : "")}
						>
							{c.label}
						</button>
					))}
				</div>
				<div key={country.value} className="country-details">
					<h3 className="strong">{country.label}</h3>
					<div dangerouslySetInnerHTML={{ __html: countryProfiles[country.value] }} />
				</div>
			</div>
		</div>
	)
}

import { SvgIcon } from "@mui/material";

export default function CaretIcon(props) {
	return (
		<SvgIcon {...props} color="red">
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
		</SvgIcon>
	);
}

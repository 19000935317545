import { Button, Paper, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { team } from "./about-us"

export const Home = () => {
	return (
		<Paper sx={{ p: 3, m: 3, borderRadius: 2, overflow: "scroll" }}>
			<Box maxWidth={1000}>
				<Typography fontWeight={500} mb={3}>
					About the Index
				</Typography>
				<Typography mb={3}>
					The COVID-19 pandemic presents an unprecedented opportunity to assess our collective ability to
					respond and adapt to external shocks. The "Social Resilience Index" (SRI) tracks Covid-19
					response mechanisms in Asia with the aim to develop a digital assessment tool for measuring
					social resilience. For the purposes of the project, social resilience is defined as formal and
					informal assistance and social protection practices which enable countries and communities to
					withstand recover, and adapt to external shocks. The project’s primary focus is on mapping-out
					the provision of social protections to vulnerable populations, such as migrants.
					<br />
					<br />
					This project’s underlying premise is that adaptation and resilience to external shocks are
					contingent on the provision of social protections to vulnerable populations, such as migrants.
					This is informed not only by the often-repeated sentiment at the start of the Covid-19 pandemic
					that “no one is safe until all are safe”, but is also grounded in the belief that assessments
					of adaptation and resilience are meaningless if they only concern the ability and opportunities
					for a select few to survive and thrive.
				</Typography>
				{/* 
				<Typography fontWeight={500} mb={3}>
					Research Questions
				</Typography>
				<Typography>
					<ul>
						<li>
							What are the social protection mechanisms extended to vulnerable groups, such as migrants,
							during the Covid-19 pandemic?
						</li>
						<li>
							How does the provision of social protection feature in designing long-term plans for economic
							and social recovery?
						</li>
						<li>
							What kind of support is provided to different categories of migrants? Does legal status
							matter in times of crisis?
						</li>
						<li>
							Are specific social protection mechanisms a permanent feature or only reflective of a
							temporary intervention due to the Covid-19 pandemic?
						</li>
						<li>
							How do countries and communities in Asia compare on their provision of social protection to
							vulnerable populations?
						</li>
					</ul>
				</Typography>
				<Typography fontWeight={500} mb={3}>
					Project Objectives
				</Typography>
				<Typography mb={3}>
					The SRI seeks to develop an assessment tool for measuring social resilience with four
					overarching conceptual, methodological, and empirical objectives, namely:
					<ol>
						<li>
							Bring conceptual clarity and highlight the critical role of social protections for social
							resilience;
						</li>
						<li>
							Advance understanding of the role of migrants/vulnerable groups for social resilience;
						</li>
						<li>
							Develop a unique open access dataset and interactive, customizable tools for data
							visualization;
						</li>
						<li>
							and Promote research with empirical focus on Asia, highlighting Asia as conceptual point of
							reference
						</li>
					</ol>
				</Typography> */}
				<Typography fontWeight={500} mb={3}>
					Principal Investigator
				</Typography>
				<Box display="flex" mb={3}>
					<img
						src="/images/marina.jpeg"
						style={{ borderRadius: 250, marginRight: 20 }}
						width={250}
						height={250}
					/>
					<Typography>
						Dr. Marina Kaneti is Assistant Professor at the LKY School of Public Policy. She specializes
						in questions of global development, including migration, environmental governance and the
						Chinese Belt and Road. In addition to developing an index on social protection mechanisms and
						emergency preparedness in Southeast Asia to capture the impact of the COVID-19 pandemic, Dr.
						Kaneti has curated a digital platform documenting ancient and modern interactions along the
						Silk and Spice Routes, and the geopolitical impact of the Belt Road initiative. The “Visual
						Archives of the Silk and Spice Routes,” funded by a NUS Start-up grant and Ford Foundation
						fellowship, contains 7000+ images, stories, and short movies. Dr. Kaneti is the recipient of
						the 2021 NUS Annual Teaching Excellence Award and the 2021 LKYSPP Digital Educator Award.
						Prior to her academic career, Dr Kaneti worked as project manager for the United Nations
						Development Program (UNDP) and equity trader on Wall Street. She occasionally engages as a
						strategy consultant for non-profit organizations, social enterprises, and think tanks.
					</Typography>
				</Box>
				<Typography fontWeight={500} mb={3}>
					The Team
				</Typography>
				<Typography>
					Dr. Kaneti is supported by a team of graduate researchers from the Lee Kuan Yew School of
					Public Policy.
				</Typography>
				<Box my={3}>
					<div className="team-grid">
						{team.map((member) => (
							<div key={member.name} className="member">
								<div className="img-wrapper">
									<img src={member.avatar} />
								</div>
								<div>
									<h5 className="strong">{member.name}</h5>
									<div>{member.title}</div>
									<div>{member.subTitle}</div>
								</div>
							</div>
						))}
					</div>
				</Box>
			</Box>
			{/* <Button variant="contained" sx={{ mt: 2 }}>
				View Dashboard
			</Button> */}
		</Paper>
	)
}

import { countriesMappedByCode } from "../data"
import { attributeKeys } from "../views/country-view/CountryView"

export function CountryComparisonBar({ chartData, pariod, indicator, selectedCountries }) {
	return (
		<div className="country-comparison">
			{selectedCountries.map((country) => (
				<div className="country">
					<h2 className="strong country-title">{countriesMappedByCode[country].label}</h2>
					{attributeKeys.map((attr) => (
						<div className="bar">
							<div className="strong label">
								<div>{attr}</div>
							</div>
							<div className="element">
								<div
									className="highlight"
									style={{
										width: `${chartData[country][attr]}%`,
										background: countriesMappedByCode[country].color,
									}}
								>
									<div className="strong highlight-label">
										<div className="base">0%</div>
										<div className="value">{chartData[country][attr]}%</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			))}
		</div>
	)
}

import { Link } from "@mui/material"
import { NavLink } from "react-router-dom"
import { countries } from "../data"

const getClassName = ({ isActive }) => (isActive ? "active" : undefined)
export function LeftNav() {
	return (
		<nav className="left-nav">
			<div style={{ paddingTop: 10 }}>
				<NavLink to="/home" className={getClassName} style={{ padding: "10px 20px" }}>
					<h3 style={{ padding: 0 }}>ABOUT THE INDEX</h3>
				</NavLink>
				<h3 className="strong">Main</h3>
				<NavLink to="/" className={getClassName}>
					Dashboard
				</NavLink>

				<NavLink to="/methodology" className={getClassName}>
					Methodology
				</NavLink>
				<h3 className="strong">Countries</h3>
				<NavLink to="/country-summary" className={getClassName}>
					Summary
				</NavLink>
				{countries.map((country) => (
					<NavLink to={`/country/${country.value}`} className={getClassName}>
						{country.label}
					</NavLink>
				))}
				<NavLink to="/contribute" className={getClassName} style={{ padding: "10px 20px" }}>
					<h3 style={{ padding: 0 }}>CONTRIBUTE DATA</h3>
				</NavLink>
			</div>
			<div className="bottom" style={{ position: "relative" }}>
				{/* <div>
					<NavLink to="/methodology" className={getClassName}>
						Methodology
					</NavLink>
					<Link
						href="https://forms.gle/6PLoRjYBSXM6UZ6Z6"
						target="_blank"
						sx={{ color: "white", textDecoration: "none" }}
					>
						Contribute
					</Link>
				</div> */}
				<div style={{ padding: 30 }}>
					<img src="/images/SRIndexLogo.png" width="100%" style={{ marginTop: "auto" }} />
				</div>
			</div>
		</nav>
	)
}

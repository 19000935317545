import { ResponsiveLine } from "@nivo/line"
import { useContext, useEffect, useMemo, useRef } from "react"
import { MasterDataContext } from "../../App"
import { countries, periodLabelsByValue, periods } from "../../data"

const getInitialAccum = (highlightCountry) => {
	return countries.reduce((accum, c) => {
		const color = highlightCountry && c.value !== highlightCountry ? `${c.color}33` : c.color

		accum[c.value] = { id: c.label, color, data: [] }
		return accum
	}, {})
}

export default function BumpChart({ indicator, attribute, highlightCountry, period }) {
	const { flatData } = useContext(MasterDataContext)
	const lineChartRef = useRef(null)

	const resiliencePeriodData = useMemo(
		() =>
			flatData.reduce((accum, point) => {
				const period = point.period
				if ([periods.DURING_COVID_Q1, periods.DURING_COVID_Q4].includes(period)) accum.push(point)
				return accum
			}, []),
		[flatData]
	)

	const chartData = useMemo(() => {
		const initialAccum = getInitialAccum(highlightCountry)
		let dataSource = period === periods.SYSTEMIC_RESILIENCE ? resiliencePeriodData : flatData
		return Object.values(
			dataSource.reduce((accum, value) => {
				if (value["Sr.No."] == indicator && value.period !== periods.DURING_COVID_ANNUAL) {
					accum[value.countryCode].data.push({
						x: periodLabelsByValue[value.period],
						y: value[attribute],
					})
				}
				return accum
			}, initialAccum)
		).sort((a, b) => (a.id > b.id ? -1 : 1))
	}, [indicator, attribute, highlightCountry, period])

	useEffect(() => {
		Array.from(lineChartRef.current.getElementsByTagName("path")).forEach((path) => {
			path.classList.add("country-path")
		})
	}, [])

	return (
		<div style={{ width: "900px", height: "400px", margin: "20px auto" }} ref={lineChartRef}>
			<ResponsiveLine
				data={chartData}
				enableCrosshair={false}
				enableSlices="x"
				enableGridY={false}
				onMouseMove={console.log}
				lineWidth={3}
				activeLineWidth={6}
				inactiveLineWidth={3}
				inactiveOpacity={0.15}
				colors={{ datum: "color" }}
				margin={{ top: 50, right: 120, bottom: 120, left: 120 }}
				xScale={{ type: "point" }}
				yScale={{
					type: "linear",
					min: 0,
					reverse: false,
				}}
				curve="cardinal"
				axisRight={null}
				axisBottom={{
					orient: "bottom",

					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legendOffset: 36,
					legendPosition: "middle",
				}}
				axisLeft={{
					orient: "left",
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: "Response Score",
					legendOffset: -40,
					legendPosition: "middle",
				}}
				pointSize={10}
				pointColor={{ theme: "background" }}
				pointBorderWidth={3}
				pointBorderColor={{ from: "serieColor" }}
				pointLabelYOffset={-12}
				legends={[
					{
						anchor: "bottom-right",
						direction: "column",
						justify: false,
						translateX: 100,
						translateY: 0,
						itemsSpacing: 0,
						itemDirection: "left-to-right",
						itemWidth: 80,
						itemHeight: 20,
						itemOpacity: 0.75,
						symbolSize: 12,
						symbolShape: "circle",
						symbolBorderColor: "rgba(0, 0, 0, .5)",
					},
				]}
			/>
		</div>
	)
}

import { Slider, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useEffect, useState } from "react"
import { annualPeriods, periods, quarterlyPeriods, resiliencePeriods } from "../data"

const { QPByValue, QPByLabel } = quarterlyPeriods.reduce(
	(accum, period) => {
		accum.QPByValue[period.value] = period.label
		accum.QPByLabel[period.label] = period.value
		return accum
	},
	{ QPByLabel: {}, QPByValue: {} }
)

const { APByValue, APByLabel } = annualPeriods.reduce(
	(accum, period) => {
		accum.APByValue[period.value] = period.label
		accum.APByLabel[period.label] = period.value
		return accum
	},
	{ APByLabel: {}, APByValue: {} }
)

const { SRPByValue } = resiliencePeriods.reduce(
	(accum, period) => {
		accum.SRPByValue[period.value] = period.label
		return accum
	},
	{ SRPByValue: {} }
)

export function Timeline({ period, setPeriod }) {
	const [timelineValue, setTimelineValue] = useState(0)
	const [timelineMode, setTimelineMode] = useState("annual")
	const timelineStep = timelineMode === "quarterly" ? 25 : 100
	const timelineMarkers =
		timelineMode === "annual"
			? annualPeriods
			: timelineMode === "quarterly"
			? quarterlyPeriods
			: timelineMode === "sys-res"
			? resiliencePeriods
			: ""

	useEffect(() => {
		if (period === periods.SYSTEMIC_RESILIENCE) {
			setTimelineMode("sys-res")
			setTimelineValue(resiliencePeriods[0].value)
		} else if (period in QPByLabel) {
			setTimelineMode("quarterly")
			setTimelineValue(QPByLabel[period])
		} else if (period in APByLabel) {
			setTimelineMode("annual")
			setTimelineValue(APByLabel[period])
		}
	}, [])

	const handlePeriodChange = (e) => {
		const periodVal = Math.round(e.target.value)
		setTimelineValue(e.target.value)
		const period =
			timelineMode === "annual"
				? APByValue[periodVal]
				: timelineMode === "quarterly"
				? QPByValue[periodVal]
				: timelineMode === "sys-res"
				? SRPByValue[periodVal]
				: ""
		setPeriod(period)
	}

	const handleTimelineModeChange = (e) => {
		const timelineMode = e.target.value
		setTimelineMode(timelineMode)
		setTimelineValue(0)
	}

	return (
		<div className="timeline">
			<ToggleButtonGroup value={timelineMode} exclusive onChange={handleTimelineModeChange}>
				<ToggleButton value="annual">Annual</ToggleButton>
				<ToggleButton value="quarterly">Quarterly</ToggleButton>
				<ToggleButton value="sys-res">Change</ToggleButton>
			</ToggleButtonGroup>
			<Slider
				min={0}
				step={timelineStep}
				value={timelineValue}
				valueLabelDisplay="off"
				marks={timelineMarkers}
				onChange={handlePeriodChange}
				classes={{ markLabel: "slider-label" }}
			/>
		</div>
	)
}

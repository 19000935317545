import { Button, Link, Box } from "@mui/material";

export function Contribute() {
  return (
    <div className="chart-section">
      <div className="chart">
        <h2 className="strong x-center">Contribute</h2>
        <Box my={3} textAlign="center" display="flex" gap={3} flexDirection="column">
          Click here to contribute data to the index.
          <Link href="https://forms.gle/j9WEQ7muKVwRLfhr7" target="_blank">
            <Button variant="contained">Contribute</Button>
          </Link>
        </Box>
      </div>
    </div>
  );
}

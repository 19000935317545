export const IndicatorDescriptors = {
	"Country Score": "Country Score",
	2: "Government Initiatives",
	2.1: "The level of inclusion of migrants in emergency preparedness plans of a state",
	2.2: "The availability of social protection mechanisms provided to by migrants in a state",
	2.3: "The ability of migrants to move freely within a state or across borders",
	3: "Non-Government Initiatives",
	3.1: "The availability of private sector-led initiatives responding to the social protection needs of migrants",
	3.2: "The availability of international organizations-led initiatives responding to the social protection needs of migrants",
	3.3: "The availability of civil society-led initiatives responding to the social protection needs of migrants",
	3.4: "The availability of community-led initiatives responding to the social protection needs of migrants",
}

const data = {
	sections: [
		{
			title: "Attributes",
			children: [
				{
					title: "Overall",
					description:
						"All persons who have moved away from his or her usual place of residence across an international border in any of the five categories below",
				},
				{
					title: "Migrant Worker",
					description:
						"A person who is engaged or employed in a remunerated activity at any point during the COVID-19 pandemic in a state of which he or she is not a national",
				},
				{
					title: "Dependent",
					description:
						"A person whose status in the receiving country is contingent on the status of the principal migrant, usually a family member",
				},
				{
					title: "Refugee",
					description:
						"A person who has fled their country for reasons of war, violence, conflict, or persecution, and are seeking asylum in another country",
				},
				{
					title: "Return Migrant",
					description:
						"A person who has returned to their country of citizenship during the COVID-19 pandemic, either voluntarily or forced",
				},
				{
					title: "Undocumented",
					description:
						"A person who is in an irregular situation and lacking the proper authorization to legally stay in a receiving state",
				},
			],
		},
		{
			title: "Government Initiatives",
			children: [
				{
					title: "Emergency Response Plan",
					description: "The level of inclusion of migrants in emergency preparedness plans of a state",
				},
				{
					title: "Legalized Social Protection",
					description:
						"The availability of social protection mechanisms provided to by migrants in a state",
				},
				{
					title: "Mobility",
					description: "The ability of migrants to move freely within a state or across borders",
				},
			],
		},
		{
			title: "Non-Government Initiatives",
			children: [
				{
					title: "Private Sector",
					description:
						"The availability of private sector-led initiatives responding to the social protection needs of migrants",
				},
				{
					title: "International Organization",
					description:
						"The availability of international organizations-led initiatives responding to the social protection needs of migrants",
				},
				{
					title: "Civil Society Organizations",
					description:
						"The availability of civil society-led initiatives responding to the social protection needs of migrants",
				},
				{
					title: "Community/Grassroots Level",
					description:
						"The availability of community-led initiatives responding to the social protection needs of migrants",
				},
			],
		},
	],
}

export function Attributes() {
	return (
		<div className="chart-section">
			<div className="chart attributes">
				{data.sections.map((section) => (
					<div className="section" key={section.title}>
						<p className="header">{section.title}</p>
						{section.children.map((subSec) => (
							<div key={subSec.title} className="item">
								<p className="item-header">{subSec.title}:</p>
								<p>{subSec.description}</p>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	)
}

import { Button, CircularProgress, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  createContext
} from "react";
import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./App.css";
import IndicatorSelectors from "./components/IndicatorSelectors";
import { SummaryChart } from "./components/SummaryChart";
import {
  attributes,
  countries,
  annualPeriods,
  countriesMappedByCode,
  getFlatData
} from "./data.js";
import { AboutUs } from "./views/about-us";
import { CountryProfiles } from "./views/country-profiles/CountryProfiles";
import { CountryView } from "./views/country-view/CountryView";
import { Dashboard } from "./views/dashboard/Dashboard";
import { Home } from "./views/home";
import { LeftNav } from "./views/LeftNav";
import { Methodology } from "./views/methodology";
import "react-toastify/dist/ReactToastify.css";
import exportFromJSON from "export-from-json";
import { Contribute } from "./views/contribute";
import masterDataJSON from "./data.json";

const sections = [
  { label: "Dashboard", value: "Map", path: "/" },
  { label: "Attributes", value: "Attributes", path: "/attributes" },
  {
    label: "Country",
    value: "Radial",
    children: countries.map((c) => ({ ...c, path: `/country/${c.value}` }))
  },
  { label: "About Us", value: "About Us", path: "/about-us" }
];

export const MasterDataContext = createContext(null);

function App() {
  const [state, setState] = useState({
    indicator: "Country Score",
    period: annualPeriods[0].label,
    attribute: attributes[0].value,
    country: ""
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [section, setSection] = useState(sections[0].value);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [masterData, setMasterData] = useState(() => getFlatData(masterDataJSON));
  const { indicator, period, attribute } = state;

  const onChange = useCallback((key) => {
    return (value) => setState((p) => ({ ...p, [key]: value }));
  }, []);

  const setCountry = (country) => {
    setSelectedCountries([country]);
    navigate("/country/" + country);
  };

  const pageTitle = useMemo(() => {
    if (pathname.startsWith("/country/")) {
      return selectedCountries
        .map((c) => countriesMappedByCode[c].label)
        .join(" - ");
    } else {
      return "Social Resilience Index (Pilot)";
    }
  }, [pathname, selectedCountries]);

  useEffect(() => {
    if (selectedCountries.length >= 1) {
      setSection("Radial");
    } else {
      setSection("Map");
    }
  }, [selectedCountries]);

  return (
    <MasterDataContext.Provider value={masterData}>
      <ToastContainer theme="light" />
      <div className="main">
        <LeftNav />
        {pathname !== "/contribute" && (
          <div className="contribute-fab">
            <Button
              variant="contained"
              color="success"
              onClick={() => navigate("/contribute")}
            >
              <h3 className="strong" style={{ margin: 0 }}>
                Contribute Data
              </h3>
            </Button>
          </div>
        )}
        <section>
          <div className="title">
            <div>{pageTitle}</div>
            <div className="subtitle">
              {pathname === "/" && "Dashboard"}
              {pathname === "/country-profiles" && "Country Profiles"}
              {pathname === "/country-summary" && "Country Summary"}
              {pathname === "/methodology" && "Methodology"}
              {pathname === "/about-us" && "About Us"}
              {pathname === "/home" && "About the Index"}
              {pathname === "/contribute" && "Contribute"}
            </div>
          </div>

          {masterData ? (
            <Routes>
              <Route
                element={
                  <>
                    <IndicatorSelectors
                      value={indicator}
                      onChange={onChange("indicator")}
                    />
                    <Outlet />
                  </>
                }
              >
                <Route
                  path="/country/:countryId"
                  element={
                    <CountryView
                      period={period}
                      indicator={indicator}
                      onStateChange={onChange}
                      selectedCountries={selectedCountries}
                      setSelectedCountries={setSelectedCountries}
                    />
                  }
                ></Route>

                <Route
                  path="/"
                  element={
                    <Dashboard
                      period={period}
                      indicator={indicator}
                      attribute={attribute}
                      onStateChange={onChange}
                      setCountry={setCountry}
                    />
                  }
                ></Route>
              </Route>
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/methodology" element={<Methodology />}></Route>
              <Route path="/country-profiles" element={<CountryProfiles />}></Route>
              <Route path="/country-summary" element={<SummaryChart />}></Route>
              <Route path="/contribute" element={<Contribute />}></Route>
              <Route path="/home" element={<Home />}></Route>
            </Routes>
          ) : (
            <Paper sx={{ m: 3, p: 5, textAlign: "center" }}>
              <CircularProgress />
            </Paper>
          )}
        </section>
      </div>
    </MasterDataContext.Provider>
  );
}

export default App;

import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Grid,
  IconButton,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import exportFromJSON from "export-from-json";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { MasterDataContext } from "../App";
import { attributes, countries, countriesMappedByCode, periodLabels } from "../data";
import CaretIcon from "./CaretIcon";
import DropdownSelect from "./DropdownSelect";
import { DynamicCaretIcon } from "./DynamicCaretIcon";
import masterDataJson from "../data.json";

export const SummaryChart = () => {
  const { flatData } = useContext(MasterDataContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [countryCode, setCountryCode] = useState(countries[0].value);
  const [period, setPeriod] = useState(periodLabels[0].value);
  const [sources, setSources] = useState(null);

  useEffect(() => {
    if (searchParams.get("country")) {
      setCountryCode(searchParams.get("country"));
    }
  }, [searchParams]);

  useEffect(() => {
    const sources = masterDataJson.reduce((accum, source) => {
      const country = source.country;
      if (accum[country]) {
        accum[country].push(source);
      } else {
        accum[country] = [source];
      }
      return accum;
    }, {});
    setSources(sources);
  }, []);

  const summaryData = useMemo(() => {
    const filteredData = flatData.filter((point) => {
      return point.countryCode === countryCode && period === point.period;
    });

    const sortedData = filteredData.sort((a, b) =>
      a["Sr.No."].toString() > b["Sr.No."].toString() ? 1 : -1
    );

    const groupedData = sortedData.reduce((accum, value) => {
      const key = value["Sr.No."];
      if (key === "Country Score") return accum;
      const keyPath = key.toString().split(".");
      if (keyPath.length === 1) {
        accum[keyPath[0]] = {
          label: value["Indicators"],
          average: value["Average"],
          data: {}
        };
      }
      if (keyPath.length === 2) {
        accum[keyPath[0]].data[keyPath[1]] = {
          label: value["Indicators"],
          average: value["Average"],
          data: {}
        };
      }
      if (keyPath.length === 3) {
        accum[keyPath[0]].data[keyPath[1]].data[keyPath[2]] = {
          label: value["Indicators"],
          average: value["Average"],
          data: value
        };
      }
      return accum;
    }, {});
    return groupedData;
  }, [countryCode, period]);

  const downloadToExcel = () => {
    exportFromJSON({
      data: flatData,
      fileName: "Social-Resilience-Index",
      exportType: "xls"
    });
  };

  return (
    <Paper sx={{ m: 3, overflow: "scroll", borderRadius: 2 }}>
      <AppBar sx={{ position: "relative", bgcolor: "#0a3d5c" }}>
        <Tabs
          value={countryCode}
          onChange={(e, newValue) => {
            setCountryCode(newValue);
          }}
          variant="full-width"
          textColor="inherit"
          indicatorColor="white"
        >
          {countries.map((country) => (
            <Tab key={country.value} label={country.label} value={country.value} />
          ))}
        </Tabs>
      </AppBar>
      <Box p={3} maxWidth={900}>
        <h2 style={{ textTransform: "uppercase", letterSpacing: "0.03em" }}>
          {countriesMappedByCode[countryCode].label}: Summary
        </h2>
        <DropdownSelect
          options={periodLabels}
          value={period}
          onChange={setPeriod}
          label="Period"
        />
        {Object.entries(summaryData).map(([groupId, { label, average, data }]) => (
          <SummaryGroup label={label} key={groupId} average={average} data={data} />
        ))}
        <div id="data-sources">
          <h2 style={{ textTransform: "uppercase", letterSpacing: "0.03em" }}>
            Data Sources
          </h2>
          <Box maxWidth="100%" overflow="scroll" py={1}>
            <Box display="flex" gap={2}>
              {sources?.[countryCode]?.map((source) => (
                <Card
                  sx={{ width: "300px", minWidth: "300px" }}
                  key={source.sourceName}
                >
                  <CardMedia
                    component="img"
                    sx={{ minHeight: "40px", maxHeight: "80px" }}
                    image={source.sourceImg}
                    alt={source.sourceName}
                  />
                  <CardContent>
                    <Typography fontWeight={"bold"}>{source.sourceName}</Typography>
                    <Box>
                      <Typography variant="body2">
                        {source.sourceDescription}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
          <Box mt={2} display="flex" gap={2}>
            <Link href="https://forms.gle/j9WEQ7muKVwRLfhr7" target="_blank">
              <Button variant="contained">Contribute</Button>
            </Link>
            <Button onClick={() => downloadToExcel()}>Download Data</Button>
          </Box>
        </div>
      </Box>
    </Paper>
  );
};

const SummaryGroup = ({ label, average, data }) => {
  return (
    <div>
      <Box display={"flex"} alignItems="center">
        <h3 style={{ textTransform: "uppercase", letterSpacing: "0.03em" }}>
          {label} : {average}
        </h3>
      </Box>
      <div>
        {Object.entries(data).map(([subgroupId, { label, average, data }]) => (
          <SummarySubGroup
            subgroupId={subgroupId}
            label={label}
            average={average}
            data={data}
          />
        ))}
      </div>
    </div>
  );
};

export const SummarySubGroup = ({ subgroupId, label, average, data }) => {
  const [show, setShow] = useState(false);
  return (
    <Box key={subgroupId}>
      <Box display="flex">
        <Box
          width="50%"
          display={"flex"}
          alignItems="center"
          bgcolor="#2f6d94"
          py={1}
          px={2}
          color="white"
          my={0.5}
          justifyContent={"space-between"}
        >
          <h4 style={{ margin: 0 }}>
            {subgroupId}. {label}
          </h4>
          <IconButton onClick={() => setShow((p) => !p)}>
            <DynamicCaretIcon up={show} color="white" />
          </IconButton>
        </Box>
        <Box width="50%" position="relative">
          <Box
            position="absolute"
            right={0}
            borderRadius={"30px"}
            border="1px solid #2f6d94"
            p={1}
            zIndex={1}
            bgcolor="white"
            top="50%"
            sx={{
              transform: "translateY(-50%)"
            }}
          >
            100
          </Box>
          <Box
            position="absolute"
            minWidth={"35px"}
            right={`${(100 - average) * 0.9}%`}
            borderRadius={"30px"}
            bgcolor="#2f6d94"
            color="white"
            p={1}
            textAlign={"center"}
            zIndex={2}
            top="50%"
            sx={{
              transform: "translateY(-50%)"
            }}
          >
            {average}
          </Box>
          <Box
            position="absolute"
            top="50%"
            sx={{
              transform: "translateY(-50%)"
            }}
            width="100%"
            height="2px"
            bgcolor="#2f6d94"
            zIndex={0}
          ></Box>
        </Box>
      </Box>
      <Collapse in={show}>
        <div>
          {Object.entries(data).map(([rowId, { label, average, data }]) => (
            <SummaryRow
              subgroupId={subgroupId}
              label={label}
              average={average}
              data={data}
              rowId={rowId}
            />
          ))}
        </div>
      </Collapse>
    </Box>
  );
};

export const SummaryRow = ({ subgroupId, rowId, label, average, data }) => {
  const [show, setShow] = useState(false);

  return (
    <div key={rowId}>
      <Box display="flex">
        <Box
          width="50%"
          display={"flex"}
          alignItems="center"
          bgcolor="#EBEBEB"
          py={0.5}
          px={2}
          my={0.5}
          justifyContent={"space-between"}
        >
          <h5 style={{ margin: 0 }}>
            {subgroupId}.{rowId} {label}
          </h5>
          <IconButton onClick={() => setShow((p) => !p)}>
            <DynamicCaretIcon up={show} />
          </IconButton>
        </Box>
        <Box width="50%" position="relative">
          <Box
            position="absolute"
            right={0}
            borderRadius={"30px"}
            border="1px solid #EBEBEB"
            p={1}
            zIndex={1}
            bgcolor="white"
            top="50%"
            sx={{
              transform: "translateY(-50%)"
            }}
          >
            100
          </Box>
          <Box
            position="absolute"
            minWidth={"35px"}
            right={`${(100 - average) * 0.9}%`}
            borderRadius={"30px"}
            bgcolor="#EBEBEB"
            color="black"
            p={1}
            textAlign={"center"}
            zIndex={2}
            top="50%"
            sx={{
              transform: "translateY(-50%)"
            }}
          >
            {average}
          </Box>
          <Box
            position="absolute"
            top="50%"
            sx={{
              transform: "translateY(-50%)"
            }}
            width="100%"
            height="2px"
            bgcolor="#EBEBEB"
            zIndex={0}
          ></Box>
        </Box>
      </Box>
      <Collapse in={show}>
        <Grid container display="flex" gap={2} my={2}>
          <Grid item xs={5} borderRadius={2} border="1px solid #EBEBEB" p={2}>
            <Typography variant="h6" textAlign="center">
              DATA
            </Typography>
            <ul>
              {attributes.map((attr) => (
                <li key={attr.value}>
                  <Box py={0.5}>
                    {attr.label}: {data[attr.value]}
                  </Box>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={5} borderRadius={2} border="1px solid #EBEBEB" p={2}>
            <Typography variant="h6" textAlign="center" mb={2}>
              SOURCES
            </Typography>
            <Typography>
              This data was curated from various sources and is open to
              crowd-sourcing inputs.
            </Typography>
            <Box justifyContent="center" mt={2} display="flex" gap={2}>
              <Link href="#data-sources">
                <Button variant="outlined">View sources</Button>
              </Link>
              <Link href="https://forms.gle/j9WEQ7muKVwRLfhr7" target="_blank">
                <Button variant="contained">Contribute</Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
};

import "./methodology.css"

const table = (
	<table className="faq-table">
		<thead>
			<tr>
				<th className="strong" colSpan={2}>
					Domain/Indicator
				</th>
				<th className="strong">Definition and Response Categories</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td className="section-header" colSpan={3}>
					Government Initiatives
				</td>
			</tr>
			<tr>
				<td className="indicator-header">1.1</td>
				<td className="indicator-header">Emergency Response Plan</td>
				<td rowSpan={8}>
					Reflects the inclusion of migrants in emergency preparedness plans of a state. Response
					categories are: 1 = Yes, 0 = No. A country scores (1=Yes) in each of the social protection
					category listed in this sub-indicator if there are plans to provide such to migrants during an
					emergency, and (0=No) if otherwise.
					<br />
					The average of the responses will be the total score for Indicator 2.1 Emergency Response
					Plans.
				</td>
			</tr>
			<tr>
				<td>1.1.1</td>
				<td>Medical Care</td>
			</tr>
			<tr>
				<td>1.1.2</td>
				<td>Unemployment</td>
			</tr>
			<tr>
				<td>1.1.3</td>
				<td>Family Benefits</td>
			</tr>
			<tr>
				<td>1.1.4</td>
				<td>Survivor Benefit</td>
			</tr>
			<tr>
				<td>1.1.5</td>
				<td>Invalidity</td>
			</tr>
			<tr>
				<td>1.1.6</td>
				<td>Sustenance</td>
			</tr>
			<tr>
				<td>1.1.7</td>
				<td>Housing</td>
			</tr>
			<tr>
				<td className="indicator-header">1.2</td>
				<td className="indicator-header">Legalized Social Protection</td>
				<td rowSpan={8}>
					Reflects the social protection mechanisms provided to by migrants in a state. Response
					categories are: 1 = Yes, 0 = No. A country scores (1=Yes) in each of the social protection
					category listed in this sub-indicator if there are legal provisions that grants social
					protection to migrants, and (0=No) if otherwise. <br />
					The average of the responses will be the total score for Indicator 2.2 Legalized Social
					Protection.
				</td>
			</tr>
			<tr>
				<td>1.2.1</td>
				<td>Medical Care</td>
			</tr>
			<tr>
				<td>1.2.2</td>
				<td>Unemployment</td>
			</tr>
			<tr>
				<td>1.2.3</td>
				<td>Family Benefits</td>
			</tr>
			<tr>
				<td>1.2.4</td>
				<td>Survivor Benefit</td>
			</tr>
			<tr>
				<td>1.2.5</td>
				<td>Invalidity</td>
			</tr>
			<tr>
				<td>1.2.6</td>
				<td>Sustenance</td>
			</tr>
			<tr>
				<td>1.2.7</td>
				<td>Housing</td>
			</tr>
			<tr>
				<td className="indicator-header">1.3</td>
				<td className="indicator-header">Mobility</td>
				<td>
					Reflects the ability of the migrants to move freely. Response categories are: 1 = Freedom of
					movement for all migrant, 0.5 = Limited movements for some migrants, 0 = No movement for all
					migrants. <br /> <i>Note: This only applies to the "During" period.</i>
				</td>
			</tr>
			<tr>
				<td>1.3.1</td>
				<td>Intra-State Mobility</td>
				<td>
					Reflects the freedom of migrants to move within a state. Response categories are: 1 = Freedom
					of movement for all migrants, 0.5 = Limited movements for some migrants, 0 = No movement for
					all migrants.
				</td>
			</tr>
			<tr>
				<td>1.3.2</td>
				<td>Inter-State Mobility</td>
				<td>
					Reflects the freedom of migrants to move across borders. Response categories are: 1 = Freedom
					of movement for all migrants, 0.5 = Limited movements for some migrants, 0 = No movement for
					all migrants.
				</td>
			</tr>
			<tr>
				<td>1.3.3</td>
				<td>Cost of Repatriation</td>
				<td>
					Reflects who covers the cost of repatriation during an emergency. Response categories are: 0 =
					Migrants bear the cost; 1 = if government covers the cost of repatriation
				</td>
			</tr>
			<tr>
				<td className="section-header" colSpan={3}>
					Non-Government Response
				</td>
			</tr>
			<tr>
				<td className="indicator-header">2.1</td>
				<td className="indicator-header">Private Sector</td>
				<td rowSpan={5}>
					Reflects the initiatives of the private sector in responding to the social protection needs of
					migrants. Response categories are: 1 = Yes, 0 = No. A country scores (1=Yes) in each of the
					social protection category listed in this sub-indicator if there is at least one publicly
					available information on an initiative specifically benefitting migrants, and (0=No) if
					otherwise. The average of the responses will be the total score for Indicator 3.1 Private
					Sector.
				</td>
			</tr>
			<tr>
				<td>2.1.1</td>
				<td>Medical Care</td>
			</tr>
			<tr>
				<td>2.1.2</td>
				<td>Unemployment</td>
			</tr>
			<tr>
				<td>2.1.3</td>
				<td>Sustenance</td>
			</tr>
			<tr>
				<td>2.1.4</td>
				<td>Housing</td>
			</tr>
			<tr>
				<td className="indicator-header">2.2</td>
				<td className="indicator-header">International Organization</td>
				<td rowSpan={6}>
					Reflects the initiatives of international organizations in responding to the social protection
					needs of migrants. Response categories are: 1 = Yes, 0 = No. A country scores (1=Yes) in each
					of the social protection category listed in this sub-indicator if there is at least one
					publicly available information on an initiative specifically benefitting migrants, and (0=No)
					if otherwise. The average of the responses will be the total score for Indicator 3.2
					International Organizations.
				</td>
			</tr>
			<tr>
				<td>2.2.1</td>
				<td>Medical Care</td>
			</tr>
			<tr>
				<td>2.2.2</td>
				<td>Unemployment</td>
			</tr>
			<tr>
				<td>2.2.3</td>
				<td>Sustenance</td>
			</tr>
			<tr>
				<td>2.2.4</td>
				<td>Legal Services</td>
			</tr>
			<tr>
				<td>2.2.5</td>
				<td>Housing</td>
			</tr>
			<tr>
				<td className="indicator-header">2.3</td>
				<td className="indicator-header">Civil Society Organizations</td>
				<td rowSpan={6}>
					Reflects the initiatives of civil society organizations in responding to the social protection
					needs of migrants. Response categories are: 1 = Yes, 0 = No. A country scores (1=Yes) in each
					of the social protection category listed in this sub-indicator if there is at least one
					publicly available information on an initiative specifically benefitting migrants, and (0=No)
					if otherwise. The average of the responses will be the total score for Indicator 3.3 Civil
					Society Organizations.
				</td>
			</tr>
			<tr>
				<td>2.3.1</td>
				<td>Medical Care</td>
			</tr>
			<tr>
				<td>2.3.2</td>
				<td>Unemployment</td>
			</tr>
			<tr>
				<td>2.3.3</td>
				<td>Sustenance</td>
			</tr>
			<tr>
				<td>2.3.4</td>
				<td>Housing</td>
			</tr>
			<tr>
				<td>2.3.5</td>
				<td>Legal Services</td>
			</tr>
			<tr>
				<td className="indicator-header">2.4</td>
				<td className="indicator-header">Community/Grassroots Level</td>
				<td rowSpan={6}>
					Reflects the initiatives of a community in responding to the social protection needs of
					migrants. Response categories are: 1 = Yes, 0 = No. A country scores (1=Yes) in each of the
					social protection category listed in this sub-indicator if there is at least one publicly
					available information on an initiative specifically benefitting migrants, and (0=No) if
					otherwise. The average of the responses will be the total score for Indicator 3.4
					Community/Grassroots Level.
				</td>
			</tr>
			<tr>
				<td>2.4.1</td>
				<td>Medical Care</td>
			</tr>
			<tr>
				<td>2.4.2</td>
				<td>Unemployment</td>
			</tr>
			<tr>
				<td>2.4.3</td>
				<td>Sustenance</td>
			</tr>
			<tr>
				<td>2.4.4</td>
				<td>Housing</td>
			</tr>
			<tr>
				<td>2.4.5</td>
				<td>Legal Services</td>
			</tr>
		</tbody>
	</table>
)

const FAQs = [
	{
		question: "What is the definition of Social Resilience?",
		response:
			"For the purposes of the project, social resilience is defined as the formal and informal assistance and social protection practices that enable communities to withstand, recover, and adapt to external shocks. This definition expands the OECD conceptualization of systemic resilience as a process of “absorption, recovery, and adaptation” by highlighting the critical role of formal and informal practices of assistance and social protection for each of the stages of resilience.",
	},
	{
		question: "Why are migrants the focus of the study?",
		response:
			"The project, using a broad definition of migrants, conceptualizes them as the lowest common denominator of vulnerable and marginalized groups. The project posits that if social protections are provided to migrants, comparable protections will most likely also be extended to other groups. The focus on migrants also reflects their critical contribution to resilience as “essential workers” and providers of remittances, particularly in Asia.",
	},
	{
		question: "What is the structure of the Pilot SRI?",
		response:
			"The SRI has 2 major domains: 1) Government Initiatives and 2) Non-Government Response.  Each domain contains indicators to reflect a country’s readiness to provide social protection mechanisms for migrants in times of crisis.  In total, there are 2 domains and 7 indicators.  Table 1 offers a summary of the structure of the index, including response categories.",
		appendTable: true,
	},
	{
		question: "What is the basis of the social protection framework?",
		response:
			"The sub-indicators (i.e. medical care, unemployment, family benefits, survivor benefit, etc.) of social protection is based on International Labor Organization’s international labour standards for social security.  The pilot study adds four more categories in the context of emergencies: mobility, housing, sustenance, and legal services.",
	},
	{
		question: "How are the countries scored?",
		response:
			"Each county is assessed using the SRI framework in 5 classification of migrants: migrant workers, dependents, refugees, return migrants, and undocumented migrants.  For each indicator, the country receives a score and is then normalized to base of 100. \n The study also employs a pre-post methodology. The indicators are assessed “before” COVID-19 and “during” COVID-19. The “during” timeframe is further broken down into quarters.",
	},
	{
		question: "What is the time frame of the study?",
		response:
			"The “before” part covers policies before the official WHO classification of the pandemic in March 2020, while the “during” period covers policies implemented from March 2020 through February 2021.",
	},
	{
		question: "How are the scores calculated?",
		response:
			"Country scores are calculated through a simple mean calculation.  After normalization, the range of scores is  between 0 to 100, with a higher score reflecting better social resilience.",
	},
	{
		question: "What are the data sources for coding?",
		response:
			"The country scores are based on publicly available data sources. These includes from official government documents, press releases, publications and reports from non-government organizations, and news reports.  The Factiva database of news media articles was also used for the study. ",
	},
	{ question: "Can I add data to the SRI?", response: "Yes, please see Contribute page." },
]

export function Methodology() {
	return (
		<div className="chart-section">
			<div className="chart methodology">
				<h3 className="strong x-center">Methodology</h3>
				<div className="methodology-section">
					<div>
						<p>
							The Social Resilience Index Pilot tracks policy changes one year into the COVID-19 Pandemic.
						</p>
						<p>
							Assessment of preparedness is based on pre-post study and captures changes in both the time
							and nature of social protection mechanisms, factoring international, government, community,
							and transnational responses.
						</p>
						<p>Migrants as unit of analysis</p>
						<ul>
							<li>Migrant Workers</li>
							<li>Refugees</li>
							<li>Dependents</li>
							<li>Undocumented</li>
							<li>Return Migrants</li>
						</ul>
					</div>
					<img src="/images/EmPI.png"></img>
				</div>
				<h3 className="strong x-center">Domains and Indicators</h3>
				<div className="attributes-section">
					<img src="/images/attributes.png"></img>
					<div className="attributes-sub-section">
						<div>
							<h4 className="strong">Note on indicators:</h4>
							<li>
								The sub-indicators (medical care, unemployment, family benefits, survivor benefit, etc.) of
								social protection is based on International Labor Organization’s international labour
								standards for social security. The study adds four more categories in the context of
								emergencies: mobility, housing, sustenance, and legal services.
							</li>
						</div>
						<div>
							<h4 className="strong">Sample questions:</h4>
							<li>
								Are there private sector or grassroots community initiatives to offer social protection to
								migrants?
							</li>
							<li>Do the emergency plans of a country extend social protection to migrants?​</li>
							<li>Do migrants have unemployment coverage?</li>
							<li>How much of migrant mobility is restricted during the emergency?</li>
						</div>
					</div>
				</div>
				<h3 className="strong x-center" style={{ paddingTop: 20 }}>
					FAQ
				</h3>
				<ol style={{ marginTop: -20 }}>
					{FAQs.map((faq) => (
						<div>
							<li>
								<h4>{faq.question}</h4>
							</li>
							<p>{faq.response}</p>
							{faq.appendTable && <div>{table}</div>}
						</div>
					))}
				</ol>
			</div>
		</div>
	)
}

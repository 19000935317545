import { useState } from "react";
import CaretIcon from "./CaretIcon";

export default function IndicatorSelect({
	label,
	options,
	value,
	groupId,
	onChange,
}) {
	const [showDropdown, setShowDropdown] = useState(false);
	const isGroupActive = value.startsWith(groupId);

	const getOpenAppendedClassName = (className) => {
		return `${className} ${showDropdown ? "open" : ""}`;
	};
	const getActiveAppendedClassName = (className, optValue) => {
		return `${className} ${optValue === value ? "active" : ""}`;
	};

	const getActiveLabel = () => {
		return options.find((opt) => opt.value === value).label;
	};

	return (
		<div
			className="indicator-select"
			onMouseOver={() => setShowDropdown(true)}
			onMouseOut={() => setShowDropdown(false)}
		>
			<div className={"label " + (isGroupActive ? "active" : "")}>
				<p>{label.toUpperCase()}</p>
			</div>
			<div className={getOpenAppendedClassName("caret")}>
				{isGroupActive && !showDropdown && getActiveLabel()}
				<CaretIcon />
			</div>
			<div className={getOpenAppendedClassName("dropdown")}>
				{options.map((opt) => (
					<div
						className={getActiveAppendedClassName("option", opt.value)}
						onClick={() => onChange(opt.value)}
					>
						{opt.label}
					</div>
				))}
			</div>
		</div>
	);
}

import { ResponsiveRadar } from "@nivo/radar"
import { useMemo } from "react"
import { countriesMappedByCode } from "./data"

export default function RadialChart({ period, indicator, selectedCountries, chartData }) {
	const colors = useMemo(() => {
		return selectedCountries.map((country) => countriesMappedByCode[country].color)
	}, [selectedCountries])

	return (
		<div className="radial-chart" key={selectedCountries.length}>
			<ResponsiveRadar
				data={chartData}
				keys={selectedCountries.map((country) => countriesMappedByCode[country].label)}
				indexBy={"attribute"}
				maxValue={110}
				margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
				borderColor={{ from: "color" }}
				gridLabelOffset={36}
				dotSize={10}
				dotColor={{ theme: "background" }}
				dotBorderWidth={2}
				colors={colors}
				blendMode="normal"
				motionConfig="slow"
				sliceTooltip={(slice) => (
					<div
						style={{
							width: "200px",
							background: "white",
							padding: "10px",
							boxShadow: "0 4px 6px 2px rgba(0, 0, 0, 0.1)",
						}}
					>
						<p>
							<b>{slice.index}</b>
						</p>
						{slice.data.map((point) => (
							<div key={point.index} style={{ display: "flex", justifyContent: "space-between" }}>
								<div>
									<span style={{ color: point.color }}>&#9673;</span>
									{point.id}
								</div>
								<div>{point.value - 10}</div>
							</div>
						))}
					</div>
				)}
				legends={[
					{
						anchor: "top-left",
						direction: "column",
						translateX: -50,
						translateY: -40,
						itemWidth: 80,
						itemHeight: 20,
						itemTextColor: "#999",
						symbolSize: 12,
						symbolShape: "circle",
						effects: [
							{
								on: "hover",
								style: {
									itemTextColor: "#000",
								},
							},
						],
					},
				]}
			/>
		</div>
	)
}
